import { PUBLIC_BOT_URL } from '$env/static/public'
import type { ApiTypes } from '@twixer/core'

export function secsToNow(from: Date): number {
  const diff = new Date().getTime() - from.getTime()
  return Math.round(diff / 1000)
}

export function dateToYYYYMMDD(d: Date) {
  const offset = d.getTimezoneOffset()
  const yourDate = new Date(d.getTime() - offset * 60 * 1000)
  return yourDate.toISOString().split('T')[0]
}

export function telegramForwardUrl(text = '', payload = '') {
  const telegramUrl = new URL('https://t.me/share/url')

  telegramUrl.searchParams.set('url', `${PUBLIC_BOT_URL}?start=${payload}`)
  telegramUrl.searchParams.set('text', text)

  // IOS bug with URL encoding
  const normalized = telegramUrl.toString().replace(/\+/g, '%20').replace(/\*/g, '%2A')

  return normalized
}

export function openTelegramForward(text = '', payload = '') {
  Telegram.WebApp.openTelegramLink(telegramForwardUrl(text, payload))
}

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function range(count: number) {
  return Array.from(Array(count).keys())
}

export function getAnglePoint(distance: number, angle: number): { x: number; y: number } {
  return {
    x: Math.cos(angle) * distance,
    y: Math.sin(angle) * distance,
  }
}
